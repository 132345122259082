<template>
  <div>
    <div v-if="selectedFormID <= 0">
      Please select {{ $formsLabelWithPrefix }} to use Routing Logic
    </div>
    <div v-else>
      <div />
      <div class="top-padding">
        <VasionDropList
          v-slot="slotItem"
          v-model="assignToField"
          :dataArray="indexFieldsForAssignTo"
          :width="'300'"
          class="w300"
          title="ASSIGN TO FIELD"
          type="plain-list"
          displayName="name"
          placeholder="Select Field..."
          valueName="value"
          @input="emitSaveAssignTo"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div v-if="!assignToSelected">
        <div class="top-padding">
          <AutoStepParameter
            ref="param1"
            :propFormID="selectedFormID"
            :propLockFields="lockFirstParam"
            :propFieldName="paramOneFieldName"
            :propValueType="paramOneValueType"
          />
        </div>
        <div class="top-padding">
          <VasionCheckbox
            id="show-second-param"
            name="show-second-param"
            :checked="showSecondParam"
            @change="toggleSecondParam()"
          >
            Enable "And" Operator
          </VasionCheckbox>
        </div>
        <div v-show="showSecondParam" class="top-padding">
          <AutoStepParameter
            ref="param2"
            :propFormID="selectedFormID"
            :propLockFields="lockSecondParam"
            :propFieldName="paramTwoFieldName"
            :propValueType="paramTwoValueType"
          />
        </div>
        <div class="top-padding">
          <VasionCheckbox
            id="show-third-param"
            name="show-third-param"
            :checked="showThirdParam"
            @change="toggleThirdParam()"
          >
            Enable "And" Operator
          </VasionCheckbox>
        </div>
        <div v-show="showThirdParam" class="top-padding">
          <AutoStepParameter
            ref="param3"
            :propFormID="selectedFormID"
            :propLockFields="lockThirdParam"
            :propFieldName="paramThreeFieldName"
            :propValueType="paramThreeValueType"
          />
        </div>
        <hr>
        <div class="md-layout w100">
          <div class="md-layout-item md-layout">
            <div>
              <VasionDropList
                v-slot="slotItem"
                v-model="autoStepAction"
                :dataArray="autoStepActionList"
                title="ACTION"
                type="plain-list"
                valueName="value"
                displayName="name"
                :showSearchField="false"
                width="220"
                @input="newAutoStepActionSelection"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
            <div v-show="showEntitySelection" class="left-padding top-padding">
              <md-radio id="user-radio" v-model="assignToType" value="user">
                User
              </md-radio>
              <md-radio id="group-radio" v-model="assignToType" value="group">
                Group
              </md-radio>
            </div>
            <div v-show="showChooseUserFromGroup" class="top-padding">
              <VasionCheckbox
                id="choose-user-from-group"
                name="choose-user-from-group"
                :checked="chooseUserFromGroup"
                @change="toggleChooseUserFromGroup()"
              >
                Choose User From Group
              </VasionCheckbox>
            </div>
          </div>
          <div class="separator-16" />
          <div class="md-layout-item">
            <VasionDropList
              v-show="showEntitySelection"
              v-slot="slotItem"
              v-model="assignToEntity"
              :dataArray="entityList"
              :openAbove="true"
              width="341"
              type="plain-list"
              title="NAME"
              displayName="name"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
        </div>
        <div class="md-layout top-padding">
          <VasionButton
            :classProp="'secondary'"
            @vasionButtonClicked="addParameterClick()"
          >
            Add Parameter
          </VasionButton>
          <VasionButton
            :classProp="'primary-light'"
            @vasionButtonClicked="removeParameterClick()"
          >
            Remove
          </VasionButton>
          <VasionCheckbox
            id="complete-step"
            class="checkbox-down"
            name="complete-step"
            :checked="completeStep"
            @change="toggleCompleteStep()"
          >
            Complete Step if No Criteria is Met
          </VasionCheckbox>
          <label class="vasion-error-text error-text">{{ errorText }}</label>
        </div>
        <div v-if="autoStepData && autoStepData.logicFields" class="grid-div vasion-html-table-default">
          <table>
            <thead>
              <tr>
                <th class="checkbox-column" />
                <th class="vasion-html-table-header">
                  <label>Action</label>
                </th>
                <th class="vasion-html-table-header boolean-column">
                  <label>Select User?</label>
                </th>
                <th class="vasion-html-table-header">
                  <label>Parameter 1</label>
                </th>
                <th class="vasion-html-table-header">
                  <label>Value 1</label>
                </th>
                <th class="vasion-html-table-header">
                  <label>Parameter 2</label>
                </th>
                <th class="vasion-html-table-header">
                  <label>Value 2</label>
                </th>
                <th class="vasion-html-table-header">
                  <label>Parameter 3</label>
                </th>
                <th class="vasion-html-table-header">
                  <label>Value 3</label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(field, index) in autoStepData.logicFields"
                :key="index"
                :class="{'vasion-lightest-grey-background': index % 2 === 0}"
              >
                <td class="checkbox-column">
                  <VasionCheckbox
                    :id="`chkRow_${field.settingNumber}`"
                    :ref="`chkRow_${field.settingNumber}`"
                    :checked="field.isChecked"
                    @value="toggleSettingChecked($event, field.settingNumber, index)"
                  />
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayActionName(field) }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ field.chooseFromGroup | boolString }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayParamName(field, 0) }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayParamValue(field, 0) }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayParamName(field, 1) }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayParamValue(field, 1) }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayParamName(field, 2) }}</label>
                </td>
                <td class="vasion-html-table-field">
                  <label>{{ displayParamValue(field, 2) }}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoStepParameter from '@/components/workflow/AutoStepParameter.vue';

export default {
  name: 'StepEntryLogic',
  components: {
    AutoStepParameter,
  },
  props: {
    propWorkflowID: {
      type: Number,
      default: 0,
      required: true,
    },
    propStepID: {
      type: Number,
      default: 0,
      required: true,
    },
    displayDataProp: {
      type: Object,
      default: () => {},
      required: false,
    },
    modifyDataProp: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data: function () {
    return {
      assignToField: {},
      autoStepAction: 1,
      autoStepActionList: [
        {
          name: 'Assign To',
          value: 1,
        },
        {
          name: 'Skip Step',
          value: 3,
        },
        {
          name: 'Complete Workflow',
          value: 4,
        },
      ],
      autoStepData: {},
      assignToEntity: {},
      assignToType: 'user',
      checkedSettingNumbers: [],
      chooseUserFromGroup: false,
      completeStep: false,
      completeWorkflow: false,
      errorText: '',
      formID: '',
      idCounter: 1,
      localDeletedSettingNumbers: [],
      localFormID: 0,
      localSettingsList: [],
      paramOneFieldName: '',
      paramOneValueType: '',
      paramThreeFieldName: '',
      paramThreeValueType: '',
      paramTwoFieldName: '',
      paramTwoValueType: '',
      selectedParameters: [],
      showSecondParam: false,
      showThirdParam: false,
      skipStep: false,
    }
  },
  computed: {
    assignToSelected() { return this.assignToField && this.assignToField.value && this.assignToField.value !== '' },
    entityList() { return this.assignToType === 'user' ? this.users : this.groups },
    groups() { return this.$store.state.common.groups },
    indexFields() { return this.$store.state.common.indexFields },
    indexFieldsForAssignTo() {
      const fieldArray = this.$store.state.common.indexFields.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })

      fieldArray.unshift({
        name: '[None]',
        value: '',
      })

       return fieldArray
    },
    lockFirstParam() {
      if (this.autoStepData.logicFields?.length > 0) {
        return this.autoStepData.logicFields[0].paramValueList[0].key.length > 0 && this.autoStepData.logicFields[0].paramValueList[0].value.length > 0
      }
      return false
    },
    lockSecondParam() {
      if (this.autoStepData.logicFields?.length > 0) {
        const secondParamIsSelected = this.autoStepData.logicFields.some((logicField) => {
          return logicField.paramValueList[1]?.key.length > 0 && logicField.paramValueList[1]?.value.length > 0
        })
        return secondParamIsSelected
      }
      return false
    },
    lockThirdParam() {
      if (this.autoStepData.logicFields?.length > 0) {
        const thirdParamIsSelected = this.autoStepData.logicFields.some((logicField) => {
          return logicField.paramValueList[2]?.key.length > 0 && logicField.paramValueList[2]?.value.length > 0
        })
        return thirdParamIsSelected
      }
      return false
    },
    saveWorkflowID() { return this.propWorkflowID <= 0 ? this.$store.state.admin.saveWorkflowID : this.propWorkflowID },
    selectedFormID() { return this.$store.state.workflow.selectedFormId },
    showChooseUserFromGroup() { return this.showEntitySelection && this.assignToType === 'group' },
    showEntitySelection() { return !(this.completeWorkflow || this.skipStep) },
    users() { return this.$store.state.common.users },
  },
  watch: {
    displayDataProp() {
      this.reloadDisplayData()
    },
    entityList: function () {
      if (this.entityList && this.entityList.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.assignToEntity = this.entityList[0]
      } else {
        this.assignToEntity = {
          name: '',
          value: 0,
        }
      }
    },
    modifyDataProp: function () {
      this.resetSaveData()
    },
    propStepID: function () {
      this.reloadDisplayData()
    },
    propWorkflowID: function () {
      this.reloadDisplayData()
    },
  },
  created: async function () {
    this.$store.dispatch('common/getUsers')
    this.$store.dispatch('common/getGroups')

    this.resetSaveData()
    this.reloadDisplayData()
  },
  methods: {
    async addParameterClick() {
      this.errorText = ''

      if (this.selectedFormID <= 0) {
        return
      }

      const firstParam = this.$refs.param1.getParamData()
      const secondParam = this.showSecondParam === true ? this.$refs.param2.getParamData() : {}
      const thirdParam = this.showThirdParam === true ? this.$refs.param3.getParamData() : {}

      if (!firstParam || !secondParam || !thirdParam) {
        return // one of the params had invalid data
      }

      // Cool, the params are great, let's make sure it's assigned to someone
      if (this.completeWorkflow === false && this.skipStep === false) {
        if (!this.assignToType || this.assignToType === '') {
          this.errorText = 'Please choose to assign to either a User or Group'
          return
        } else if (!this.assignToEntity || this.assignToEntity.value <= 0) {
          this.errorText = this.assignToType === 'user' ? 'Please select a User' : 'Please select a Group'
          return
        }
      }

      let entityID = this.assignToEntity.value
      if (this.completeWorkflow === true) {
        entityID = -1
      } else if (this.skipStep === true) {
        entityID = -2
      }
      if (this.localFormID !== this.selectedFormID) {
        // the selected form has changed which invalidates any existing params.  remove all of those and then save the new param
        this.checkedSettingNumbers = this.autoStepData.logicFields.map((field) => {
          return field.settingNumber
        })

        await this.removeParameterClick()
        this.localFormID = this.selectedFormID
      }

      const savePayload = {
        settingNumber: 0,
        assignToEntityID: entityID,
        isGroup: entityID > 0 && this.assignToType !== 'user',
        isChooseFromGroup: entityID > 0 && this.assignToType !== 'user' && this.chooseUserFromGroup === true,
        reassign: false,
        parameterFields: [firstParam],
      }

      if (this.showSecondParam === true) {
        savePayload.parameterFields.push(secondParam)
      }

      if (this.showThirdParam === true) {
        savePayload.parameterFields.push(thirdParam)
      }

      this.localSettingsList.push(savePayload)

      const displaySetting = this.buildDisplaySetting(savePayload)
      this.autoStepData.logicFields.push(displaySetting)
      this.emitWorkflowIsDirty()
    },
    buildDisplaySetting(setting) {
      let computedValue = ''
      const displayParamList = []

     // First parameter for the setting
      const firstParameter = setting.parameterFields[0]
      const firstDisplayParam = {
        key: this.indexFields.find(f => f.fieldID === firstParameter.fieldID).name,
        value: firstParameter.secondValue === ''
          ? `${firstParameter.firstValueOperator} ${firstParameter.firstValue}`
          : `${firstParameter.firstValueOperator} ${firstParameter.firstValue} and ${firstParameter.secondValueOperator} ${firstParameter.secondValue}`,
      }
      displayParamList.push(firstDisplayParam)

      // Second parameter for the setting
      if (this.showSecondParam) {
        const secondParameter = setting.parameterFields[1]
        computedValue = `${secondParameter.firstValueOperator} ${secondParameter.firstValue}`

        if (secondParameter.secondValue !== '' && secondParameter.secondValueOperator !== '') {
          computedValue = `${computedValue} and ${secondParameter.secondValueOperator} ${secondParameter.secondValue}`
        }

        const secondDisplayParam = {
          key: this.indexFields.find(f => f.fieldID === secondParameter.fieldID).name,
          value: computedValue,
        }

        displayParamList.push(secondDisplayParam)
      }

      // Third parameter for the setting
      if (this.showThirdParam) {
        const thirdParameter = setting.parameterFields[2]
        computedValue = `${thirdParameter.firstValueOperator} ${thirdParameter.firstValue}`

        if (thirdParameter.secondValue !== '' && thirdParameter.secondValueOperator !== '') {
          computedValue = `${computedValue} and ${thirdParameter.secondValueOperator} ${thirdParameter.secondValue}`
        }

        const thirdDisplayParam = {
          key: this.indexFields.find(f => f.fieldID === thirdParameter.fieldID).name,
          value: computedValue,
        }

        displayParamList.push(thirdDisplayParam)
      }

      let entityID = this.assignToEntity.value
      let entityAssignTo = ''
      if (this.completeWorkflow === true) {
        entityID = -1
        entityAssignTo = 'Complete Workflow'
      } else if (this.skipStep === true) {
        entityID = -2
        entityAssignTo = 'Skip Step'
      } else {
        entityAssignTo = this.entityList.find(e => e.value === setting.assignToEntityID).name
      }
      const displaySetting = {
        assignToName: entityAssignTo,
        chooseFromGroup: entityID > 0 && this.assignToType !== 'user' && this.chooseUserFromGroup === true,
        settingNumber: 0,
        paramValueList: displayParamList,
        isChecked: false,
      }

      return displaySetting
    },
    buildSavePayload() {
      if (this.localDeletedSettingNumbers.length > 0 && this.localSettingsList.length > 0) {
        const newLocalSettingsList = []
        this.localSettingsList.forEach((setting) => {
          if (!(this.localDeletedSettingNumbers.some((deleteNumber) => deleteNumber === setting.settingNumber))) {
              newLocalSettingsList.push(setting)
          }
        })
      }

      const payload = {
        workflowID: this.saveWorkflowID,
        stepID: this.propStepID,
        completeStep: this.completeStep === true,
        deleteSettingNumbers: this.localDeletedSettingNumbers,
        assignToField: this.assignToSelected ? this.assignToField.value : '',
        settingsList: this.localSettingsList && this.localSettingsList.length > 0 && !this.assignToSelected ? this.localSettingsList : [],
      }

      return payload
    },
    buildStepDisplayPayload() {
      const payload = this.autoStepData
      payload.completeStepIfNoCriteriaMet = this.completeStep
      payload.formID = this.localFormID
      payload.assignToField = this.assignToSelected ? this.assignToField.value : ''
      return payload
    },
    displayActionName(field) {
      switch (field.assignToName) {
        case 'Complete Workflow':
        case 'Skip Step':
          return field.assignToName
        case 'Complete Step':
          return 'Skip Step'
        default:
          return (field.reassign === true ? 'Reassign to ' : 'Assign to ') + field.assignToName
      }
    },
    displayParamName(field, index) {
      if (!field || index < 0 || !field.paramValueList || field.paramValueList.length <= index) {
        return ''
      }

      return field.paramValueList[index].key
    },
    displayParamValue(field, index) {
      if (!field || index < 0 || !field.paramValueList || field.paramValueList.length <= index) {
        return ''
      }

      return field.paramValueList[index].value
    },
    emitWorkflowIsDirty() {
      this.$emit('markAsDirty', true)
      this.$store.dispatch('workflow/clearTabError', 'initialLogic')
    },
    getParamFormAutoStepData(paramIndex) {
      const retValue = {
        fieldName: '',
        valueType: '',
      }

      if (!this.autoStepData || !this.autoStepData.parameters || this.autoStepData.parameters.length <= paramIndex) {
        return retValue
      }

      retValue.fieldName = this.autoStepData.parameters[paramIndex].fieldName
      retValue.valueType = this.autoStepData.parameters[paramIndex].valueType

      return retValue
    },
    newAutoStepActionSelection() {
      this.skipStep = (this.autoStepAction.value === 3)
      this.completeWorkflow = (this.autoStepAction.value === 4)
    },
    async reloadDisplayData() {
      let data = this.displayDataProp
      if (!data && this.propWorkflowID > 0 && this.propStepID >= 0) {
        const loadDataPayload = {
          workflowID: this.propWorkflowID,
          stepID: this.propStepID,
        }

         data = await this.$store.dispatch('workflow/getWorkflowAutoStepDisplayData', loadDataPayload)
      }

      if (!data) {
        data = {
          parameters: [],
          logicFields: [],
          completeStepIfNoCriteriaMet: false,
          assignToField: '',
          formID: 0,
        }
      }

      this.resetAutoStepData(data)
    },
    reloadParamFields() {
      if (!this.autoStepData || !this.autoStepData.parameters) {
        this.showSecondParam = false
        this.showThirdParam = false
      } else {
        this.showSecondParam = this.autoStepData.parameters.length > 1
        this.showThirdParam = this.autoStepData.parameters.length > 2
      }

      let paramData = this.getParamFormAutoStepData(0)
      this.paramOneFieldName = paramData.fieldName
      this.paramOneValueType = paramData.valueType

      paramData = this.getParamFormAutoStepData(1)
      this.paramTwoFieldName = paramData.fieldName
      this.paramTwoValueType = paramData.valueType

      paramData = this.getParamFormAutoStepData(2)
      this.paramThreeFieldName = paramData.fieldName
      this.paramThreeValueType = paramData.valueType

      if (this.$refs.param1) {
        this.$refs.param1.resetCompareFields()
      }

      if (this.$refs.param2) {
        this.$refs.param2.resetCompareFields()
      }

      if (this.$refs.param3) {
        this.$refs.param3.resetCompareFields()
      }
    },
    async removeParameterClick() {
      if (!this.selectedParameters || this.selectedParameters.length === 0) {
        return
      }

      this.resetCheckboxes()
      const localRemoveParameters = []
      let count = 0
      this.autoStepData.logicFields.forEach(field => {
        if (!this.selectedParameters.includes(count)) {
          localRemoveParameters.push(field)
          field.isChecked = false
        }
        count += 1
      })
      this.autoStepData.logicFields = localRemoveParameters
      this.selectedParameters = []

      this.checkedSettingNumbers.forEach((n) => {
        this.localDeletedSettingNumbers.push(n)
      })
      this.checkedSettingNumbers = []
      this.emitWorkflowIsDirty()
    },
    async resetAutoStepData(data) {
      if (data.assignToField !== '') {
         this.assignToField = this.indexFieldsForAssignTo.find((field) => field.value === data.assignToField) || ''
      }

      if (this.autoStepData) {
        this.autoStepData.logicFields = []
      }

      if (data?.logicFields) {
        data.logicFields = data.logicFields.map((element) => {
          element.isChecked = false
          return element
        })
      }

      this.autoStepData = JSON.parse(JSON.stringify(data))
      this.checkedSettingNumbers = []

      if (this.autoStepData) {
        this.completeStep = this.autoStepData.completeStepIfNoCriteriaMet
        this.localFormID = this.autoStepData.formID
      } else {
        this.completeStep = false
        this.localFormID = 0
      }

      this.reloadParamFields()
    },
    resetCheckboxes() {
      this.autoStepData.logicFields.forEach((f) => {
        f.isChecked = false
      })
    },
    resetSaveData() {
      if (this.modifyDataProp) {
        this.completeStep = this.modifyDataProp.completeStep
        this.localDeletedSettingNumbers = this.modifyDataProp.deleteSettingNumbers
        this.localSettingsList = this.modifyDataProp.settingsList
      } else {
        this.completeStep = false
        this.localDeletedSettingNumbers = []
        this.localSettingsList = []
      }
    },
    toggleChooseUserFromGroup() {
      this.chooseUserFromGroup = !this.chooseUserFromGroup
    },
    toggleCompleteStep() {
      this.completeStep = !this.completeStep
    },
    toggleSecondParam() {
      this.showSecondParam = !this.showSecondParam
    },
    toggleSettingChecked(e, settingNumber, index) {
      this.autoStepData.logicFields[index].isChecked = e
      const indexOf = this.checkedSettingNumbers.indexOf(settingNumber)
      if (e) {
        this.selectedParameters.push(index)
      } else {
        this.selectedParameters.splice(this.selectedParameters.indexOf(index), 1)
      }

      if (indexOf >= 0) {
        this.checkedSettingNumbers.splice(indexOf, 1)
      } else {
        this.checkedSettingNumbers.push(settingNumber)
      }
    },
    toggleThirdParam() {
      this.showThirdParam = !this.showThirdParam
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .align-left {
    text-align: left;
  }

  .boolean-column {
    width: 125px;
  }

  .checkbox-column {
    width: 40px;
  }

  .top-padding {
    padding-top: 15px;
  }

  .checkbox-down {
    padding-top: 10px;
  }

  .left-padding {
    padding-left: 60px;
  }

  .w100 {
    width: 100%;
  }

  .w250px {
    width: 200px;
    border: solid;
  }

  .separator-16 {
    width: 16px;
  }

  hr {
    margin-top: 16px;
    border-width: 1px;
    border-color: $grey-100;
  }

  .error-text {
    padding-top: 13px;
    padding-left: 15px;
  }

</style>
