<template>
  <div>
    <table class="w100">
      <tr v-if="showTitleRow">
        <td class="check-column" />
        <td class="button-column" />
        <td>
          <label class="vasion-input-label-top">BUTTON LABEL</label>
        </td>
        <td class="standard-column">
          <label class="vasion-input-label-top">STATUS MESSAGE</label>
        </td>
        <td class="standard-column">
          <label class="vasion-input-label-top">EMAIL TEMPLATE</label>
        </td>
        <td class="center-text check-column">
          <label class="vasion-input-label-top">COMMENT REQUIRED</label>
        </td>
      </tr>
      <tr>
        <td class="check-column dataRow">
          <VasionCheckbox
            :id="`enable-button-${buttonName}`"
            name="enable-button"
            :checked="buttonEnabled"
            @change="toggleCheckbox('buttonEnabled');"
          >
            {{ buttonName }}
          </VasionCheckbox>
        </td>
        <td class="button-column">
          <VasionButton
            v-show="showConfigureButton && buttonEnabled"
            :id="`configure-button-${buttonName}`"
            :name="`configure-button-${buttonName}`"
            :icon="'VasionSettingsIcon'"
            title="Configure"
            @vasionButtonClicked="configureButtonClick()"
          />

          <VasionButton
            v-show="buttonName === 'Reassign' && buttonEnabled"
            name="Cog"
            class="step-button"
            :icon="'VasionSettingsIcon'"
            title="Configure Step"
            @vasionButtonClicked="showUserGroup = !showUserGroup"
          />
        </td>
        <td>
          <VasionInput
            v-show="buttonEnabled"
            :id="`button-label-${buttonName}`"
            v-model="buttonLabel"
            title=""
            name="button-label"
            placeholder="Enter Label..."
            inputType="top-white"
          />
        </td>
        <td class="standard-column">
          <VasionInput
            v-show="buttonEnabled"
            :id="`button-status-${buttonName}`"
            v-model="statusMessage"
            title=""
            name="button-status"
            placeholder="Enter Status..."
            inputType="top-white"
          />
        </td>
        <td class="standard-column">
          <VasionDropList
            v-show="buttonEnabled"
            :id="`button-email-template-${buttonName}`"
            v-slot="slotItem"
            v-model="emailTemplateObject"
            title=""
            name="button-email-template"
            type="plain-list"
            :dataArray="templates"
            valueName="value"
            displayName="name"
            placeholder="Select Email Template..."
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </td>
        <td class="check-column">
          <VasionCheckbox
            v-show="buttonEnabled"
            :id="`comment-required-${buttonName}`"
            name="comment-required"
            class="center-check"
            :checked="commentRequired"
            @change="toggleCheckbox('commentRequired');"
          />
        </td>
      </tr>
    </table>
    <md-dialog id="reassign-forward-modal" :md-active.sync="showUserGroup" class="email-modal-div">
      <div class="body-div">
        <VasionUserGroupSelect
          :modalTitle="'Select a User from Group'"
          :defaultSelected="groupID"
          :includeNoneValue="true"
          :groupsOnly="true"
          @noButtonClick="showUserGroup = false"
          @yesButtonClick="handleGroupSelection"
        />
      </div>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'ButtonInfo',
  props: {
    buttonInfoProp: {
      type: Object,
      required: false,
      default: () => {},
    },
    buttonName: {
      type: String,
      required: true,
    },
    reassignGroupID: {
      type: Number,
      required: false,
      default: null,
    },
    showConfigureButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTitleRow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      buttonEnabled: false,
      buttonLabel: '',
      commentRequired: false,
      emailTemplateID: null,
      emailTemplateObject: '',
      groupID: 0,
      showUserGroup: false,
      statusMessage: '',
    }
  },
  computed: {
    templates() {
      const templateArray = this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })

      templateArray.unshift({
        name: '[None]',
        value: 0,
      })

      return templateArray
    },
  },
  watch: {
    buttonInfoProp: function () {
      this.setLocalValues()
    },
  },
  created: function () {
    this.setLocalValues()
  },
  methods: {
    configureButtonClick() {
      this.$emit('configureButtonClick')
    },
    getLocalData() {
      const result = {
        buttonEnabled: this.buttonEnabled,
        buttonLabel: this.buttonEnabled ? this.buttonLabel : '',
        commentRequired: this.buttonEnabled ? this.commentRequired : false,
        emailTemplateID: this.buttonEnabled && this.emailTemplateObject && this.emailTemplateObject.value !== undefined && this.emailTemplateObject.value > 0 ? this.emailTemplateObject.value : null,
        statusMessage: this.buttonEnabled ? this.statusMessage : '',
      }

      return result
    },
    handleGroupSelection(selectedEntity) {
      if (!selectedEntity || !selectedEntity.type) {
        this.groupID = 0
        this.assignToDisplayName = ''
      } else if (selectedEntity.type === 'groups') {
        this.groupID = selectedEntity.value
      }

      this.showUserGroup = false
    },
    setLocalValues() {
      this.buttonEnabled = false
      this.buttonLabel = ''
      this.statusMessage = ''
      this.emailTemplateID = null
      this.commentRequired = false
      // eslint-disable-next-line prefer-destructuring
      this.emailTemplateObject = this.templates[0]

      if (!this.buttonInfoProp || this.buttonInfoProp.buttonEnabled === undefined) {
        return
      }
      this.buttonEnabled = this.buttonInfoProp.buttonEnabled
      this.buttonLabel = this.buttonInfoProp.buttonLabel
      this.statusMessage = this.buttonInfoProp.statusMessage
      this.emailTemplateID = this.buttonInfoProp.emailTemplateID
      this.commentRequired = this.buttonInfoProp.commentRequired
      this.groupID = this.reassignGroupID

      if (this.emailTemplateID !== null && this.emailTemplateID > 0) {
        this.emailTemplateObject = this.templates.find((f) => f.value === this.emailTemplateID) || ''

        if (!this.emailTemplateObject) {
          // eslint-disable-next-line prefer-destructuring
          this.emailTemplateObject = this.templates[0]
        }
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
    },
  },
}
</script>

<style lang="scss" scoped>
  .center-text {
    text-align: center;
  }

  .center-check {
    position: relative;
    width: 30px;
    left: calc(50% - 13px);
  }

  .button-column {
    width: 48px;
  }

  .w100 {
    width: 100%;
  }

  .standard-column {
    width: 24%;
    padding-left: 10px;
  }

  .check-column {
    width: 12%;
  }

  .dataRow {
    height: 45px;
  }

</style>
