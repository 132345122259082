<template>
  <div class="action-details-div">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="!isUploadingFile && !isViewingDocument">
      <div v-show="showUserGroup" class="body-div">
        <VasionUserGroupSelect
          modalTitle="Select a User or Group"
          :userOnly="false"
          @noButtonClick="showUserGroup = false"
          @yesButtonClick="handleUserGroupSelection"
        />
      </div>
      <div v-show="!showUserGroup && !showGroupsOnly" class="body-div">
        <table class="action-details-table">
          <tr>
            <td class="vasion-page-title">
              Custom Button Settings
            </td>
            <td />
          </tr>
          <tr>
            <td>
              <VasionInput
                id="action-name-text"
                v-model="actionName"
                title="Action Name"
                name="action-name-text"
                placeholder="Enter Name..."
                inputType="top-white"
              />
            </td>
            <td>
              <VasionInput
                id="status-message-text"
                v-model="workflowStatus"
                title="Status Message"
                name="status-message-text"
                placeholder="Enter Status..."
                inputType="top-white"
              />
            </td>
          </tr>
          <tr>
            <td>
              <VasionDropList
                id="button-action-droplist"
                v-slot="slotItem"
                v-model="selectedButtonAction"
                title="BUTTON ACTION"
                name="button-action-droplist"
                type="plain-list"
                :dataArray="buttonActionsArray"
                width="100%"
                valueName="value"
                displayName="name"
                placeholder=""
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td>
              <VasionCheckbox
                id="run-other-action-check"
                name="run-other-action-check"
                :checked="commentRequired"
                color="#95989a"
                class="control-centered"
                @change="toggleCheckbox('commentRequired');"
              >
                Comment Required
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <table v-if="selectedButtonAction && selectedButtonAction.value === 2" class="action-details-table indented">
          <tr v-for="(sig, index) in signatureForms" :key="index">
            <td>
              <VasionDropList
                v-slot="slotItem"
                v-model="sig.attributeForm"
                :title="formsLabel"
                name="form-droplist"
                type="plain-list"
                :dataArray="attributeFormList"
                width="385"
                valueName="value"
                displayName="name"
                placeholder=""
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td class="w30">
              <VasionButton
                name="set-signature-location-button"
                :classProp="'secondary'"
                :isDisabled="!(sig.attributeForm && sig.attributeForm.value)"
                class="control-centered"
                @vasionButtonClicked="setSignatureLocation(sig.tempId)"
              >
                Set Signature Location
              </VasionButton>
            </td>
            <td>
              <VasionButton
                name="set-signature-location-button"
                :classProp="'secondary'"
                class="control-centered"
                @vasionButtonClicked="removeSignatureForm(index)"
              >
                Remove
              </VasionButton>
            </td>
          </tr>
        </table>
        <table v-show="selectedButtonAction && selectedButtonAction.value === 2" class="action-details-table indented">
          <tr>
            <td>
              <VasionButton
                name="add-new--button"
                :classProp="'secondary'"
                @vasionButtonClicked="addNewSignatureForm"
              >
                Add New
              </VasionButton>
            </td>
          </tr>
        </table>
        <table v-show="selectedButtonAction && selectedButtonAction.value === 2" class="action-details-table">
          <tr>
            <td>
              <VasionCheckbox
                id="burn-signature-check"
                name="burn-signature-check"
                :checked="burnSignature"
                color="#95989a"
                @change="burnSignature = !burnSignature"
              >
                Burn Signature
              </VasionCheckbox>
            </td>
            <td>
              <VasionCheckbox
                id="apply-date-signature-check"
                name="apply-date-signature-check"
                :checked="applyDateToSignature"
                color="#95989a"
                @change="applyDateToSignature = !applyDateToSignature"
              >
                Apply Date to Signature
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 3" class="set-fields">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedForm"
            :title="formsLabel"
            name="form-droplist"
            type="plain-list"
            :dataArray="attributeFormList"
            width="385"
            valueName="value"
            displayName="name"
            placeholder=""
            @input="updateSelectedForm()"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <div id="field-list" class="vasion-html-table-default">
            <table>
              <thead>
                <tr class="white-background">
                  <th class="wide">
                    <label class="vasion-html-table-header">Field</label>
                  </th>
                  <th>
                    <label class="vasion-html-table-header">Prompt</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-header">Required</label>
                  </th>
                  <th class="field-type-column">
                    <label class="vasion-html-table-hselectedFormFieldseader">Auto Value</label>
                  </th>
                </tr>
              </thead>
              <tbody class="scrollTBody">
                <tr v-for="(field, index) in setFields" :key="index" :class="{'lightestGreyBackground': index % 2 === 0 }">
                  <td class="pointer">
                    <label class="vasion-html-table-field pointer">{{ field.title }}</label>
                  </td>
                  <td class="pointer">
                    <VasionCheckbox
                      id="'prompt'+index"
                      color="#95989a"
                      :checked="field.prompt"
                      class="prompt-checkbox"
                      @change="setPrompt(index)"
                    />
                  </td>
                  <td class="pointer">
                    <VasionCheckbox
                      id="'required'+index"
                      color="#95989a"
                      :checked="field.required"
                      class="required-checkbox"
                      @change="setRequired(index)"
                    />
                  </td>
                  <td class="pointer auto-value-input">
                    <VasionInput v-model="field.default" inputType="top-white" :isDisabled="field.prompt" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 4" id="lookup-section">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedLookup"
            title="LOOKUP"
            name="lookup-droplist"
            type="plain-list"
            :dataArray="lookupList"
            width="385"
            valueName="value"
            displayName="name"
            placeholder=""
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 5" class="">
          <table class="action-details-table indented">
            <tr>
              <td>
                <md-radio id="email-radio" v-model="radio" value="email">
                  Send to Email
                </md-radio>
                <md-radio id="field-radio" v-model="radio" value="field">
                  Send to Field
                </md-radio>
              </td>
            </tr>
            <tr>
              <td v-if="radio === 'email'">
                <VasionInput
                  id="send-to-email-text"
                  v-model="sendToEmail"
                  title="Send to Email"
                  name="send-to-email-text"
                  placeholder="Enter Email..."
                  inputType="top-white"
                />
              </td>
              <td v-if="radio === 'field'">
                <VasionDropList
                  id="send-to-field"
                  v-slot="slotItem"
                  v-model="sendToField"
                  title="Send to Field"
                  name="send-to-field"
                  type="plain-list"
                  :dataArray="selectedObjectId === 0 ? allIndexFields : indexFields"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td>
                <VasionDropList
                  id="email-templates"
                  v-slot="slotItem"
                  v-model="emailTemplateIDForSendEmail"
                  title="Email Templates"
                  name="email-templates"
                  type="plain-list"
                  :dataArray="emailTemplatesForSendEmail"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
            </tr>
            <tr>
              <td>
                <VasionCheckbox
                  id="attach-document"
                  name="attach-document"
                  class="control-centered"
                  color="#95989a"
                  :checked="attachDocument"
                  @change="toggleCheckbox('attachDocument');"
                >
                  Attach document
                </VasionCheckbox>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="selectedButtonAction && selectedButtonAction.value === 6">
          <table class="action-details-table indented">
            <tr>
              <td>
                <VasionDropList
                  id="workflow-field"
                  v-slot="slotItem"
                  v-model="workflowFieldObj"
                  title="Workflow"
                  name="workflow-field"
                  type="plain-list"
                  :dataArray="workflowListValues"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td />
            </tr>
          </table>
        </div>
        <div v-else-if="selectedButtonAction && selectedButtonAction.value === 7">
          <table class="action-details-table indented">
            <tr>
              <td>
                <VasionDropList
                  id="document-template"
                  v-slot="slotItem"
                  v-model="selectedTemplate"
                  title="Document Template"
                  name="document-template"
                  type="plain-list"
                  :dataArray="templateList"
                  width="100%"
                  valueName="documentTemplateID"
                  displayName="name"
                  placeholder=""
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td />
            </tr>
          </table>
        </div>
        <div v-else-if="selectedButtonAction && selectedButtonAction.value === 8">
          <table class="action-details-table indented">
            <tr>
              <td>
                <VasionDropList
                  id="signature-template"
                  v-slot="slotItem"
                  v-model="selectedSignatureTemplate"
                  title="Signature Template"
                  name="signature-template"
                  type="plain-list"
                  :dataArray="signatureTemplateList"
                  width="100%"
                  valueName="value"
                  displayName="name"
                  placeholder=""
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </td>
              <td>
                <VasionCheckbox
                  id="use-original-document"
                  name="use-original-document"
                  :checked="useOriginalDocument"
                  color="#95989a"
                  class="control-centered"
                  @change="toggleCheckbox('useOriginalDocument');"
                >
                  Use Original Document
                </VasionCheckbox>
              </td>
            </tr>
          </table>
        </div>
        <table class="action-details-table">
          <tr>
            <td>
              <VasionCheckbox
                v-if="linkedActionsArray.length > 0"
                id="run-other-action-check"
                name="run-other-action-check"
                :checked="runOtherAction"
                color="#95989a"
                :isDisabled="isBottomSectionDisabled"
                @change="toggleCheckbox('runOtherAction');"
              >
                Run Other Action When Complete
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <table v-show="runOtherAction" class="action-details-table">
          <tr v-show="runOtherAction">
            <td>
              <VasionDropList
                id="run-other-action-droplist"
                v-slot="slotItem"
                v-model="selectedLinkedAction"
                title="Action"
                name="run-other-action-droplist"
                type="plain-list"
                :dataArray="linkedActionsArray"
                width="100%"
                valueName="value"
                displayName="name"
                placeholder=""
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td />
          </tr>
        </table>
        <hr v-show="!runOtherAction">
        <table v-show="!runOtherAction" class="action-details-table">
          <tr>
            <td>
              <div class="md-layout group-check-box">
                <VasionCheckbox
                  id="reassign-document-check"
                  name="reassign-document-check"
                  class="reassign-document-check"
                  color="#95989a"
                  :checked="reassign"
                  :isDisabled="isBottomSectionDisabled"
                  @change="toggleCheckbox('reassign');"
                >
                  Reassign Document
                </VasionCheckbox>
                <VasionCheckbox
                  id="complete-step-check"
                  name="complete-step-check"
                  :checked="completeStep"
                  color="#95989a"
                  :isDisabled="isBottomSectionDisabled"
                  @change="toggleCheckbox('completeStep');"
                >
                  Complete the Step
                </VasionCheckbox>
              </div>
            </td>
            <td>
              <VasionDropList
                id="complete-step-type-droplist"
                v-slot="slotItem"
                v-model="selectedCompleteStepType"
                title="Complete Step Type"
                name="complete-step-type-droplist"
                type="plain-list"
                :dataArray="completeStepTypeArray"
                :openAbove="true"
                :isDisabled="!completeStep"
                valueName="value"
                displayName="name"
                width="100%"
                placeholder=""
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
          </tr>
          <tr>
            <td>
              <div class="md-layout">
                <VasionCheckbox
                  v-show="assignDocumentVisible"
                  id="assign-document-check"
                  name="assign-document-check"
                  class="assign-document-check"
                  :checked="assignDocument"
                  @change="toggleCheckbox('assignDocument');"
                >
                  Assign Document
                </VasionCheckbox>
                <VasionCheckbox
                  v-show="assignDocumentVisible && assignDocument && groupID > 0"
                  id="user-from-group-check"
                  name="user-from-group-check"
                  :checked="chooseUserFromGroup"
                  @change="toggleCheckbox('chooseUserFromGroup');"
                >
                  User from Group
                </VasionCheckbox>
              </div>
            </td>
            <td v-show="assignDocumentVisible && assignDocument">
              <VasionButton id="button-user-group" :classProp="'primary'" @vasionButtonClicked="showUserGroup = true">
                {{ assignmentButtonText }}
              </VasionButton>
            </td>
          </tr>
          <tr>
            <td>
              <VasionDropList
                v-show="assignDocumentVisible"
                id="email-template-droplist"
                v-slot="slotItem"
                v-model="emailTemplateObject"
                title="Email Template"
                name="email-template-droplist"
                type="plain-list"
                :dataArray="emailTemplates"
                valueName="value"
                displayName="name"
                width="385"
                placeholder="Select Email Template..."
                :openAbove="true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
            <td>
              <VasionInput
                v-show="showSendEmailTo"
                id="send-to-email-text"
                v-model="emailAddress"
                title="Send to Email"
                name="send-to-email-text"
                placeholder="Enter Email..."
                inputType="top-white"
              />
            </td>
          </tr>
        </table>
      </div>
      <div v-show="!showUserGroup && !showGroupsOnly" class="bottom-div">
        <span class="error-text">{{ errorText }}</span>
        <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
          Cancel
        </VasionButton>
        <VasionButton id="button-save" :classProp="'primary'" @vasionButtonClicked="saveClick()">
          Save
        </VasionButton>
      </div>
    </div>

    <!-- FILE UPLOAD  ------------------------------------------------>
    <div v-if="isUploadingFile && !isViewingDocument" class="document-upload">
      <span class="headline">Upload a Document</span>
      <span class="error-text">{{ errorText }}</span>
      <div class="upload-container">
        <div class="file-pond-block-wrapper">
          <file-pond ref="pond" :server="serverOptions" />
        </div>
      </div>
      <div class="auto-left-margin">
        <VasionButton
          id="close-file-upload"
          name="close-file-upload"
          :classProp="'secondary'"
          @vasionButtonClicked="isUploadingFile = false"
        >
          CANCEL
        </VasionButton>
        <VasionButton
          id="close-file-upload"
          name="close-file-upload"
          :classProp="'primary'"
          :isDisabled="!fileUploaded"
          @vasionButtonClicked="isUploadingFile = false; isViewingDocument = true; getImageProperties();"
        >
          CONTINUE
        </VasionButton>
      </div>
    </div>

    <!-- FILE VIEWER  ------------------------------------------------>
    <div v-if="!isUploadingFile && isViewingDocument && imagePropertiesLoaded" class="document-viewer">
      <div>
        <div class="auto-sign-header">
          <VasionButton
            id="close-viewer"
            name="close-viewer"
            title="Close Viewer"
            :icon="'VasionCancelIcon'"
            @vasionButtonClicked="isViewingDocument = false;"
          />
          <span class="headline">Auto Sign Configuration</span>
        </div>
        <div class="md-layout toolbar-div">
          <div class="toolbar-height">
            <VasionButton
              id="btnFirstPage"
              name="btnFirstPage"
              title="First Page"
              :icon="'VasionFirstPageIcon'"
              :isDisabled="isPreviousButtonsDisabled"
              @vasionButtonClicked="goToFirstPage()"
            />
            <VasionButton
              id="btnPreviousPage"
              name="btnPreviousPage"
              title="Previous Page"
              :icon="'VasionPreviousPageIcon'"
              :isDisabled="isPreviousButtonsDisabled"
              @vasionButtonClicked="goToPreviousPage()"
            />
          </div>
          <div class="w60 moveUp">
            <md-field>
              <md-input v-model="pageDisplay" class="w60 centerText" />
            </md-field>
          </div>
          <div class="toolbar-height">
            <VasionButton
              id="btnNextPage"
              name="btnNextPage"
              title="Next Page"
              :icon="'VasionNextPageIcon'"
              :isDisabled="isNextButtonsDisabled"
              @vasionButtonClicked="goToNextPage()"
            />
            <VasionButton
              id="btnLastPage"
              name="btnLastPage"
              title="Last Page"
              :icon="'VasionLastPageIcon'"
              :isDisabled="isNextButtonsDisabled"
              @vasionButtonClicked="goToLastPage()"
            />
          </div>
          <div class="divider" />
          <VasionButton
            id="add-signature"
            name="add-signature"
            title="Signature"
            :icon="'VasionGestureIcon'"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.signatureZone)"
          />
          <VasionButton
            id="save-signature"
            name="save-signature"
            title="Save"
            :icon="'VasionSaveIcon'"
            @vasionButtonClicked="saveSignatureZones()"
          />
        </div>
      </div>
      <div class="md-layout-item">
        <div class="lightestGreyBackground mainImageDiv">
          <DocumentImage
            v-if="uploadedfilePath && uploadedfilePath !== ''"
            ref="mainImage"
            :key="currentPageNumber"
            :documentID="0"
            :pageNumber="Number(currentPageNumber)"
            :width="viewerWidth"
            :height="viewerHeight"
            :zoomScale="0.99"
            :largeDefaultLoadSize="true"
            :context="'AutoSignConfig'"
            :imageEndpoint="'document/getFileImageURL'"
            :filePath="uploadedfilePath"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import DocumentImage from '@/components/document/DocumentImage.vue';
import Loading from 'vue-loading-overlay';
import { toBase64 } from '@/store/helperModules/storage.module'

import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'CustomButtonActionDetails',
  components: {
    DocumentImage,
    Loading,
  },
  props: {
    actionDetails: {
      type: Object,
      required: false,
      default: () => {},
    },
    availableLinkedActions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: function () {
    return {
      actionName: '',
      annotationConfig: {
        signatureZone: { type: 'signatureZone', color: '', subType: '' },
      },
      applyDateToSignature: false,
      assignDocument: false,
      assignToDisplayName: '',
      attachDocument: false,
      base64PdfDataPrefix: 'data:application/pdf;base64,',
      base64TifDataPrefix: 'data:image/tiff;base64,',
      burnSignature: false,
      chooseUserFromGroup: false,
      commentRequired: false,
      completeStep: false,
      completeStepTypeArray: [
        {
          name: 'Approve',
          value: 1,
        },
        {
          name: 'Reject',
          value: 2,
        },
        {
          name: 'Forward',
          value: 3,
        },
        {
          name: 'Complete',
          value: 4,
        },
        {
          name: 'AutoStep',
          value: 5,
        },
      ],
      currentPageNumber: 1,
      currentSignature: {},
      emailAddress: '',
      emailTemplateIDForSendEmail: '',
      emailTemplateObject: '',
      errorText: '',
      fileFullPage: '',
      fileUploaded: false,
      fullPage: true,
      groupID: 0,
      id: 0,
      imageDetails: {},
      imagePropertiesLoaded: false,
      isLoading: false,
      isUploadingFile: false,
      isViewingDocument: false,
      linkedActionsArray: [],
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      radio: 'email',
      readyToLoad: false,
      reassign: false,
      runOtherAction: false,
      selectedButtonAction: { name: '', value: '' },
      selectedCompleteStepType: { name: 'Approve', value: 1 },
      selectedForm: { name: '', value: 0 },
      selectedLinkedAction: { name: '', value: '' },
      selectedLookup: { name: '', value: 0 },
      selectedSignatureTemplate: { name: '', value: '' },
      selectedTemplate: {},
      sendToEmail: '',
      sendToField: { name: '', value: '' },
      serverOptions: { process: this.processHandler },
      setFields: [],
      showGroupsOnly: false,
      showUserGroup: false,
      signatureForms: [{
        attributeForm: { name: '', value: '' },
        filePath: '',
        tempId: uuid.v1().toString(), // This is being set to handle objects that have not been saved yet, since we could have multiple of them
        signatureId: 0,
        height: '',
        left: '',
        top: '',
        width: '',
        pageNumber: '',
      }],
      totalPages: 1,
      uploadedFileName: '',
      uploadedfilePath: '',
      uploadedFileString: '',
      useOriginalDocument: false,
      userID: 0,
      viewerHeight: 864,
      viewerWidth: 664,
      workflowFieldObj: {},
      workflowList: [],
      workflowStatus: '',
    }
  },
  computed: {
    activeForm() { return this.$store.state.attributeForm.activeIndexForm },
    allIndexFields() { return this.$store.state.storage.uniqueFieldNames },
    assignDocumentVisible() { return this.completeStep || this.reassign },
    assignmentButtonText() {
      if (!this.assignToDisplayName) {
        return 'Select a User or Group'
      }

      return `Assign To: ${this.assignToDisplayName}`
    },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    buttonActionsArray() {
      const buttons = [
        {
          name: 'None',
          value: 1,
        },
        {
          name: 'Auto Signature',
          value: 2,
        },
        {
          name: 'Set Fields',
          value: 3,
        },
        {
          name: 'Lookup',
          value: 4,
        },
        {
          name: 'Send Email',
          value: 5,
        },
        {
          name: 'Start Workflow',
          value: 6,
        },
      ]
      if (this.templateList?.length > 0) {
        buttons.push({ name: 'Document Template', value: 7 })
      }
      if (this.signatureTemplateList?.length > 0) {
        buttons.push({ name: 'Signature Template', value: 8 })
      }
      return buttons
    },
    emailTemplates() {
      const templateArray = this.$store.state.common.emailTemplates.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })

      templateArray.unshift({
        name: '[None]',
        value: 0,
      })

      return templateArray
    },
    emailTemplatesForSendEmail() {
      return this.emailTemplates.filter(t => {
        return t.name !== '[None]'
      })
    },
    formsLabel() { return $formsLabel.toUpperCase() },
    indexFields() {
      return this.$store.state.common.indexFields.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },

    isBottomSectionDisabled() { return this.selectedButtonAction && ((this.selectedButtonAction.value === 6) || (this.selectedButtonAction.value === 8 && this.useOriginalDocument)) },
    isDuplicateActionName() { return this.linkedActionsArray.some(action => action.name === this.actionName) },
    isNextButtonsDisabled() { return this.currentPageNumber >= this.totalPages },
    isPreviousButtonsDisabled() { return this.currentPageNumber <= 1 },
    lookupList() {
      return this.$store.state.fieldMapping.lookupList.map(item => {
        return {
          name: item.name,
          value: item.id,
        }
      })
    },
    pageDisplay: {
      get: function () {
          return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    selectedEmailTemplateID() { return this.showSendEmailTo ? this.emailTemplateObject.value : null },
    selectedObject() { return this.attributeFormList.find(form => { return form.value === this.$store.state.workflow.selectedFormId }) },
    selectedObjectId() { return this.$store.state.workflow.selectedFormId },
    showSendEmailTo() { return this.assignDocumentVisible && this.emailTemplateObject && this.emailTemplateObject.value !== undefined && this.emailTemplateObject.value > 0 },
    signatureTemplateList() {
      const { Values } = this.$store.state.digitalSignature.templateData
      return Values?.map(template => {
        return { name: template.sName, value: template.iID }
      })
    },
    signatureZone() { return this.$store.state.workflow.signatureZoneSaveObject },
    templateList() {
      if (this.selectedObjectId === 0) {
        return this.$store.state.common.documentTemplates
      }
      return this.$store.state.common.documentTemplates.filter(template => { return template.objectName === this.selectedObject.name })
    },
    workflowListValues() {
      return this.workflowList.map(a => {
        return {
          name: a.sName,
          value: a.iID,
        }
      })
    },
  },
  watch: {
    completeStep: function () {
      if (this.completeStep) {
        this.reassign = false
      }
    },
    reassign: function () {
      if (this.reassign) {
        this.completeStep = false
      }
    },
    signatureZone: function () {
      // This code detects when a signature zone was saved in the document viewer, and then saves the details locally for later saving to the database
      if (this.signatureZone && this.signatureZone.Annotations && this.signatureZone.Annotations.Values && this.signatureZone.Annotations.Values.length > 0) {
        const annotation = this.signatureZone.Annotations.Values[0] // There should only be one
        const pageDpi = this.imageDetails.OriginalDPI
        const scaleX = this.imageDetails.OriginalWidth / this.viewerWidth / pageDpi
        const scaleY = this.imageDetails.OriginalHeight / this.viewerHeight / pageDpi
        const sigToUpdate = this.signatureForms.find(f => f.tempId === this.currentSignature.tempId)
        const sigToUpdateIndex = this.signatureForms.indexOf(sigToUpdate)
        if (sigToUpdate) {
          sigToUpdate.filePath = this.uploadedfilePath
          sigToUpdate.height = annotation.Height * scaleY
          sigToUpdate.left = annotation.PosX * scaleX
          sigToUpdate.top = annotation.PosY * scaleY
          sigToUpdate.width = annotation.Width * scaleX
          sigToUpdate.pageNumber = this.signatureZone.PageNumber
        }
        if (sigToUpdateIndex !== -1) {
          this.signatureForms[sigToUpdateIndex] = sigToUpdate
        }
      }
    },
  },
  async created() {
    this.isLoading = true
    this.updateSelectedForm()
    const promises = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('fieldMapping/getDatabaseLookups', true),
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
      this.$store.dispatch('common/getIndexFieldsForForm', this.selectedObjectId),
      this.$store.dispatch('common/getDocumentTemplates'),
      this.$store.dispatch('digitalSignature/getSignatureDocumentTemplates'),
    ])
    // eslint-disable-next-line
    this.workflowList = promises[2]

    if (this.availableLinkedActions) {
      this.availableLinkedActions.map((element) => {
        this.linkedActionsArray.push(element)
        return true
      })
    }

    if (!this.actionDetails || !this.actionDetails.actionName) {
      this.isLoading = false
      return
    }
    this.actionName = this.actionDetails.actionName
    this.commentRequired = this.actionDetails.commentRequired
    this.id = this.actionDetails.ID
    this.workflowStatus = this.actionDetails.workflowStatus
    this.reassign = this.actionDetails.reassign
    this.completeStep = this.actionDetails.completeStep
    this.assignDocument = this.actionDetails.assignDocument
    this.chooseUserFromGroup = this.actionDetails.chooseUserFromGroup
    this.emailAddress = this.actionDetails.emailAddress
    this.userID = this.actionDetails.userID
    this.groupID = this.actionDetails.groupID
    this.runOtherAction = false
    this.selectedTemplate = this.templateList.find(template => { return template.documentTemplateID === Number(this.actionDetails.customHeaderProperties?.iCreateDocumentTemplateID) })

    if (this.userID > 0) {
      const foundUser = this.$store.state.common.users.find((u) => u.value === this.userID)
      if (foundUser) {
        this.assignToDisplayName = foundUser.name
      }
    } else if (this.groupID > 0) {
      const foundGroup = this.$store.state.common.groups.find((g) => g.value === this.groupID)
      if (foundGroup) {
        this.assignToDisplayName = foundGroup.name
      }
    }

    if (this.actionDetails.completeStepType !== undefined) {
      const foundType = this.completeStepTypeArray.find(element => element.value === this.actionDetails.completeStepType)
      if (foundType) {
        this.selectedCompleteStepType = foundType
      }
    }

    if (this.actionDetails.emailTemplateID !== null && this.actionDetails.emailTemplateID > 0) {
      this.emailTemplateObject = this.emailTemplates.find((f) => f.value === this.actionDetails.emailTemplateID) || ''
    }

    if (this.actionDetails.linkedActionName) {
      this.selectedLinkedAction = this.linkedActionsArray.find((f) => f.value === this.actionDetails.linkedActionName)
      this.runOtherAction = this.selectedLinkedAction !== null
    }

    await this.setButtonActionSigFormsAndSetFields()
    this.isLoading = false
  },
  methods: {
    addNewAnnotation(config) {
      this.$store.dispatch('document/setAnnotationConfig', config)
    },
    addNewSignatureForm() {
      this.signatureForms.push({
        attributeForm: {
          name: '',
          value: '',
        },
        filePath: '',
        tempId: uuid.v1().toString(), // This is being set to handle objects that have not been saved yet, since we could have multiple of them
        signatureId: 0,
        height: '',
        left: '',
        top: '',
        width: '',
        pageNumber: '',
      })
    },
    async buildAndStoreAnnotationsObject() {
      if (this.currentSignature.height !== '1' && this.currentSignature.width !== '1') {
        const annotations = [
          {
            AnnType: 'Rectangle',
            height: this.currentSignature.height,
            width: this.currentSignature.width,
            top: this.currentSignature.top,
            left: this.currentSignature.left,
            pageNumber: this.currentSignature.pageNumber,
          },
        ]

        await this.$store.dispatch('workflow/storeSignatureZoneAnnotations', annotations)
      } else {
        await this.$store.dispatch('workflow/storeSignatureZoneAnnotations', [])
      }
    },
    cancelClick() {
      this.$emit('close')
    },
    getImageProperties() {
      const payload = {
        FilePath: this.uploadedfilePath,
        ImageWidth: this.viewerWidth,
        ImageHeight: this.viewerHeight,
        IncludeAnno: false,
        PageNum: 1,
        RenderMode: 0,
        ThumbWidth: 100,
        ThumbHeight: 150,
        ZoomMode: 1,
        RotateDegrees: -2,
      }

      this.$store.dispatch('document/getFileImageURL', payload).then(async (imageData) => {
        if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
          || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
            // need to flip the bounds
            const temp = this.viewerHeight
            this.viewerHeight = this.viewerWidth
            this.viewerWidth = temp
        }

        if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
          this.viewerHeight = imageData.height
          this.viewerWidth = imageData.width
        }

        this.imagePropertiesLoaded = true

        this.imageDetails = {
            ImageSource: imageData.url,
            Width: imageData.width,
            Height: imageData.height,
            Annotations: { Values: imageData.annotations },
            PageDPI: imageData.PageDPI,
            OriginalDPI: imageData.OriginalDPI,
            OriginalHeight: imageData.OriginalHeight,
            OriginalWidth: imageData.OriginalWidth,
            FilePath: this.filePath,
        }
      })
    },
    goToFirstPage() {
      this.goToPage(1)
    },
    goToLastPage() {
      this.goToPage(this.totalPages)
    },
    goToNextPage() {
      if ((this.currentPageNumber + 1) <= this.totalPages) {
        this.goToPage(this.currentPageNumber + 1)
      }
    },
    async goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPageNumber = pageNumber
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1)
      }
    },
    handleUserGroupSelection(selectedEntity) {
      if (!selectedEntity || !selectedEntity.type) {
        this.groupID = 0
        this.userID = 0
        this.assignToDisplayName = ''
      } else if (selectedEntity.type === 'groups') {
        this.userID = 0
        this.groupID = selectedEntity.value
        this.assignToDisplayName = selectedEntity.name
      } else if (selectedEntity.type === 'users') {
        this.groupID = 0
        this.userID = selectedEntity.value
        this.assignToDisplayName = selectedEntity.name
      }

      this.showUserGroup = false
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      this.errorText = ''
      let rawData = ''

      const pdfIndex = file.name.indexOf('.pdf')
      const tiffIndex = file.name.indexOf('.tiff')
      const tifIndex = file.name.indexOf('.tif')
      if (pdfIndex < 0 && tiffIndex < 0 && tifIndex < 0) {
        const errorText = 'Only PDF, TIF, and TIFF file types are supported. Please try uploading one of those file types.'
        console.warn(errorText)
        this.errorText = errorText
      }
      this.uploadedFileString = await toBase64(file)
      this.uploadedFileName = file.name
      if (pdfIndex >= 0) {
        rawData = this.uploadedFileString.substring(this.base64PdfDataPrefix.length)
      } else if (tiffIndex >= 0) {
        rawData = this.uploadedFileString.substring(this.base64TifDataPrefix.length)
      } else if (tifIndex >= 0) {
        rawData = this.uploadedFileString.substring(this.base64TifDataPrefix.length)
      }

      progress(true, 0, 1024);
      load(file.name)

      this.uploadedfilePath = await this.$store.dispatch('workflow/uploadWorkflowAutoSignatureFile', { name: this.uploadedFileName, fileData: rawData })
      this.totalPages = await this.$store.dispatch('document/getFilePageCount', { Value: this.uploadedfilePath })
      this.fileUploaded = true

      return {
        abort: () => {
          if (abort !== undefined) {
            abort();
          }
        },
      };
    },
    removeSignatureForm(index) {
      this.signatureForms.splice(index, 1)
    },
    saveClick() {
      this.errorText = ''
      if (!this.actionName) {
        this.errorText = 'Please enter an Action Name.'
        return
      }
      if (this.actionName.trim() === '') {
        this.errorText = 'Action Name cannot only include spaces.'
        return
      }

      if (this.isDuplicateActionName) {
        this.errorText = 'Please enter a unique Action Name.'
        return
      }

      if (this.runOtherAction && (!this.selectedLinkedAction || this.selectedLinkedAction.value === '')) {
        this.errorText = 'Please select a Linked Action.'
        return
      }

      if (this.assignDocumentVisible && this.assignDocument && this.userID <= 0 && this.groupID <= 0) {
        this.errorText = 'Please select a User or Group.'
        return
      }

      if (this.selectedButtonAction.value === 1) { // Button Action = Nothing
        this.signatureForms = []
      }

      let customHeaderProperties = null
      let customListProperties = null
      if (this.selectedButtonAction.value === 2) { // Button Action = Auto Signature
        customHeaderProperties = {
          bBurnSignature: this.burnSignature ? 'True' : 'False',
          bApplyDate: this.applyDateToSignature ? 'True' : 'False',
        }

        const settingList = this.signatureForms.map((s) => {
          // TODO: setting the iAutoSigID to 0 when creating a new one is fine.
          // However, when loading existing objects from the server, we'll need to pass down the ID from there so the Server knows to do an update
          return {
              iAutoSigID: s.signatureId !== '' && s.signatureId !== '0' ? s.signatureId : '0',
              dLeft: s.left,
              dTop: s.top,
              dWidth: s.width,
              dHeight: s.height,
              iPageNumber: s.pageNumber,
              sSampleFile: s.filePath.replace('C:\\MV_Files\\_AutoSignFiles\\SampleFile.pdf', ''),
              iIndexFormID: s.attributeForm.value.toString(),
            }
        })

        customListProperties = {
          oSettingList: settingList,
        }
      }
      if (this.selectedButtonAction.value === 3) { // Button Actions = Set Fields
        if (this.selectedForm.value === undefined) {
          this.errorText = `Please select ${$formsLabelWithPrefix.toLowerCase()}`
          return
        }
        customHeaderProperties = {
          iIndexFormID: this.selectedForm.value,
        }

        const filteredFields = this.setFields.filter(field => field.prompt || field.default !== '')
        if (filteredFields.length === 0) {
          this.errorText = 'Please select at least one field'
          return
        }
        const fields = filteredFields.map((s) => {
          return {
            iID: 0,
            iSetIndexFieldID: 0,
            iIndexFieldID: s.indexFieldID,
            sFieldTitle: s.title,
            sFieldName: s.name,
            iControlType: s.controlType,
            sDropDownString: '',
            bPrompt: s.prompt,
            bRequired: s.required,
            sDefault: s.default,
          }
        })

        customListProperties = {
          oFields: fields,
        }
      }
      if (this.selectedButtonAction.value === 4) { // Button Actions = Lookup
        if (this.selectedLookup.value <= 0) {
          this.errorText = 'Please select a Lookup'
          return
        }
        customHeaderProperties = {
          iLookupID: this.selectedLookup.value,
        }
      }

      if (this.selectedButtonAction.value === 5) {
        if (this.emailTemplateIDForSendEmail.value === undefined) {
          this.errorText = 'Please select e-mail template'
          return
        }
        if (this.selectedObjectId === 0) {
          this.sendToField.value = this.sendToField.name
        }
        if (this.sendToField.value === undefined && this.sendToEmail === '') {
          this.errorText = 'Please select either Send to Field or Send to Email'
          return
        }
        customHeaderProperties = {
          iEmailTemplateID: this.emailTemplateIDForSendEmail.value.toString(),
          sSendToFieldName: this.radio === 'field' ? this.sendToField.value : null,
          sSendToAddress: this.radio === 'email' ? this.sendToEmail : null,
          bAttachDocument: this.attachDocument.toString(),
        }
      }

      if (this.selectedButtonAction.value === 6) {
        if (!this.workflowFieldObj?.value) {
          this.errorText = 'Please select a workflow field'
          return
        }
        customHeaderProperties = {
          iWFID: this.workflowFieldObj.value.toString(),
        }
        customListProperties = {}
      }

      if (this.selectedButtonAction.value === 7) {
        if (!this.selectedTemplate.documentTemplateID) {
          this.errorText = 'Please select a Document Template'
          return
        }
        customHeaderProperties = {
          iCreateDocumentTemplateID: this.selectedTemplate.documentTemplateID.toString(),
        }
        customListProperties = {}
      }
      if (this.selectedButtonAction.value === 8) {
        if (!this.selectedSignatureTemplate?.value) {
          this.errorText = 'Please select a Signature Template'
          return
        }
        customHeaderProperties = {
          iSigDocTemplateID: this.selectedSignatureTemplate.value.toString(),
          bSendCurrentDocument: this.useOriginalDocument.toString(),
        }
        customListProperties = {}
      }

      const buttonDetails = {
        ID: this.id,
        actionName: this.actionName,
        actionType: this.selectedButtonAction ? this.selectedButtonAction.name : '',
        workflowStatus: this.workflowStatus,
        completeStep: this.completeStep,
        completeStepType: this.completeStep && this.selectedCompleteStepType && this.selectedCompleteStepType.value !== undefined ? this.selectedCompleteStepType.value : 1,
        assignDocument: this.assignDocumentVisible ? this.assignDocument : false,
        chooseUserFromGroup: this.assignDocumentVisible && this.assignDocument && this.groupID > 0 ? this.chooseUserFromGroup : false,
        userID: this.assignDocumentVisible && this.assignDocument ? this.userID : 0,
        groupID: this.assignDocumentVisible && this.assignDocument ? this.groupID : 0,
        reassign: this.reassign,
        commentRequired: this.commentRequired,
        emailTemplateID: this.selectedEmailTemplateID,
        emailAddress: this.selectedEmailTemplateID !== null ? this.emailAddress : '',
        linkedActionName: this.runOtherAction && this.selectedLinkedAction ? this.selectedLinkedAction.value : '',
        runOtherAction: this.runOtherAction,
        customHeaderProperties: customHeaderProperties,
        customListProperties: customListProperties,
      }
      this.$emit('button-updated', buttonDetails)
    },
    saveSignatureZones() {
      this.isViewingDocument = false;
    },
    async setButtonActionSigFormsAndSetFields() {
      if (this.actionDetails.actionType === 'Auto Signature') {
        this.selectedButtonAction = {
          name: 'Auto Signature',
          value: 2,
        }

        const self = this
        if (this.actionDetails.customListProperties && this.actionDetails.customListProperties.oSettingList) {
          // eslint-disable-next-line prefer-arrow-callback
          this.signatureForms = this.actionDetails.customListProperties.oSettingList.map(function (s) {
            const form = self.attributeFormList.find(f => f.value === Number(s.iIndexFormID))
            return {
              attributeForm: {
                name: form.name,
                value: form.value,
              },
              filePath: s.sSampleFile.replace('C:\\MV_Files\\_AutoSignFiles\\SampleFile.pdf', ''),
              tempId: uuid.v1().toString(),
              signatureId: s.iAutoSigID,
              height: s.dHeight,
              left: s.dLeft,
              top: s.dTop,
              width: s.dWidth,
              pageNumber: s.iPageNumber,
            }
          })
        }
      } else if (this.actionDetails.actionType === 'Set Fields') {
        this.selectedButtonAction = {
          name: 'Set Fields',
          value: 3,
        }
        this.selectedForm.value = Number(this.actionDetails.customHeaderProperties.iIndexFormID)
        await this.updateSelectedForm()
        const [...oFields] = this.actionDetails.customListProperties.oFields
        if (this.actionDetails.customListProperties && this.actionDetails.customListProperties.oFields) {
          for (let i = 0; i < oFields.length; i += 1) {
            for (let j = 0; j < this.setFields.length; j += 1) {
              if (this.setFields[j].indexFieldID.toString() === oFields[i].iIndexFieldID.toString()) {
                const prompt = oFields[i].bPrompt.toString()
                const required = oFields[i].bRequired.toString()
                this.setFields[j].prompt = (prompt.toLowerCase() === 'true')
                this.setFields[j].required = (required.toLowerCase() === 'true')
                this.setFields[j].default = oFields[i].sDefault
              }
            }
          }
        }
      } else if (this.actionDetails.actionType === 'Send Email') {
        this.selectedButtonAction = {
          name: 'Send Email',
          value: 5,
        }
        this.emailTemplateIDForSendEmail = this.emailTemplates.find(a => a.value === Number(this.actionDetails.customHeaderProperties.iEmailTemplateID))
        if (this.selectedObjectId !== 0) {
          this.sendToField = this.actionDetails.customHeaderProperties.sSendToFieldName ? this.indexFields.find(a => a.value === this.actionDetails.customHeaderProperties.sSendToFieldName) : ''
        } else {
          this.sendToField = this.actionDetails.customHeaderProperties.sSendToFieldName ? this.allIndexFields.find(a => a.value === this.actionDetails.customHeaderProperties.sSendToFieldName) : ''
        }
        if (!this.sendToField) {
          this.sendToField = {}
        }
        this.sendToEmail = this.actionDetails.customHeaderProperties.sSendToAddress
        this.attachDocument = this.actionDetails.customHeaderProperties.bAttachDocument === 'true'
        if (this.sendToField === '' || Object.keys(this.sendToField).length === 0) {
          this.radio = 'email'
        } else {
          this.radio = 'field'
        }
      } else if (this.actionDetails.actionType === 'Lookup') {
        this.selectedButtonAction = {
          name: 'Lookup',
          value: 4,
        }
        this.selectedLookup.value = Number(this.actionDetails.customHeaderProperties.iLookupID)
      } else if (this.actionDetails.actionType === 'Start Workflow') {
        this.selectedButtonAction = {
          name: 'Start Workflow',
          value: 6,
        }
        this.workflowFieldObj = this.workflowListValues.find(a => a.value === Number(this.actionDetails.customHeaderProperties.iWFID))
      } else if (this.actionDetails.actionType === 'Document Template') {
        this.selectedButtonAction = {
          name: 'Document Template',
          value: 7,
        }
      } else if (this.actionDetails.actionType === 'Signature Template') {
        this.selectedButtonAction = {
          name: 'Signature Template',
          value: 8,
        }
      this.selectedSignatureTemplate = this.signatureTemplateList.find(t => t.value === Number(this.actionDetails.customHeaderProperties?.iSigDocTemplateID))
      this.useOriginalDocument = this.actionDetails.customHeaderProperties?.bSendCurrentDocument.toLowerCase() === "true"
      } else {
        this.selectedButtonAction = {
          name: 'None',
          value: 1,
        }
      }
    },
    setPrompt(index) {
      this.setFields[index].prompt = !this.setFields[index].prompt
    },
    setRequired(index) {
      this.setFields[index].required = !this.setFields[index].required
      if (this.setFields[index].required) {
        this.setFields[index].prompt = true
      }
    },
    async setSignatureLocation(tempId) {
      await this.$store.dispatch('workflow/storeSignatureZoneAnnotations', {})
      this.currentPageNumber = 1
      this.currentSignature = this.signatureForms.find(f => f.tempId === tempId)
      if (!this.currentSignature || typeof this.currentSignature.filePath === 'undefined') {
        console.warn('Could not find current signature')
        return
      }

      await this.buildAndStoreAnnotationsObject()

      if (this.currentSignature.filePath === '') {
        this.fileUploaded = false
        this.isUploadingFile = true
        this.isViewingDocument = false
      } else {
        this.uploadedfilePath = this.currentSignature.filePath
        this.totalPages = await this.$store.dispatch('document/getFilePageCount', { Value: this.uploadedfilePath })
        this.fileUploaded = true
        this.isUploadingFile = false
        this.isViewingDocument = true
        this.getImageProperties()
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
    },
    async updateSelectedForm() {
      await this.$store.dispatch('attributeForm/getFormDetails', this.selectedForm.value)
      this.setFields = this.activeForm?.fields.map((s) => {
        return {
          id: 0,
          setIndexFieldID: 0,
          indexFieldID: s.fieldID,
          title: s.title,
          name: s.title,
          controlType: s.type,
          dropDownString: '',
          prompt: false,
          required: false,
          default: '',
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .overflow-auto-div {
    overflow: auto;
  }

  .assign-document-check {
    margin-right: 40px;
  }

  .reassign-document-check {
    margin-right: 25px;
  }

  .action-details-table {
    width: 100%;

    td {
      width: 50%;
      padding: 5px;
    }
  }

  .column-align-right {
    text-align: right;
  }

  .action-details-div {
    overflow-y: auto;
    width: 800px;
    padding: 5px;
  }

  .body-div {
    min-height: 420px;
    width: 100%;
    padding: 5px;
  }

  .bottom-div {
    width: 100%;
    vertical-align: middle;
    line-height: 50px;
    text-align: right;
  }

  hr {
    border-width: 1px;
    border-color: $grey-100;
  }

  .group-check-box {
    width: 100%;
    margin-top: 20px;
  }

  .w30 {
    width: 30% !important;
  }

  .control-centered {
    margin-top: 20px;
  }

  .document-upload {
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(22, 23, 24, 0.08);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;

    .auto-left-margin {
      margin-left: auto;
    }
  }

  .document-viewer {
    height: 100%;
    overflow: hidden;
  }

  .divider {
    width: 1px;
    background-color: $divider-light-grey;
  }

  .headline {
    @include Headline;
    width: 100%;
  }

  .error-text {
    @include Text;
    width: 100%;
    color: red;
    margin: 20px;
  }

  .w60 {
    width: 86px;
    height: 30px;
  }

  .centerText {
    text-align: center;
  }

  .moveUp {
    position: relative;
    top: -20px;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
  }

  .toolbar-height {
    height: 25px;
  }

  .auto-sign-header {
    display: flex;
    padding: 15px;
  }

  .mainImageDiv {
    padding: 16px 16px 16px 16px;
    overflow: auto;
    height: calc(100vh - 153px);
    text-align: center;
  }

  .lightestGreyBackground {
    background-color: $grey-75;
  }

  .set-fields{
    margin: 5px;
  }

  .prompt-checkbox{
    margin-left: 30px;
  }

  .required-checkbox{
    margin-left: 35px;
  }

  .white-background{
    background-color: white;
  }

  .wide{
    width: 45%;
  }

  #field-list{
    margin-top: 16px;
  }

  #lookup-section {
    padding: 5px;
  }

  .auto-value-input{
    padding: 3px;
  }

  @media screen and (max-width: 1600px) {
    .document-upload {
      width: 80%;
    }
  }
  @media screen and (max-width: 1108px) {
    .document-upload {
      width: 50%;
    }
    .upload-container {
      width: 60%;
    }
  }
</style>
