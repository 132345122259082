<template>
  <div class="main vasion-calc-height vld-parent">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="showEmailTemplate">
      <EmailTemplate ref="emailTemplateComponent" :emitClose="true" @editingComplete="emailTemplateComplete" />
    </div>
    <div v-show="showStepDetails">
      <WorkflowStepDetails
        ref="stepDetailsComponent"
        :stepData="editStepData"
        :workflowID="workflowId"
        @cancelClick="hideEditStepData"
        @doneClick="updateWorkflowStep"
      />
    </div>
    <div v-show="showWorkflow">
      <!-- TOOLBAR -->
      <div id="toolbar">
        <h1 id="title" :class="{ 'new-workflow-name' : workflowId === 0 }">
          {{ workflow.name }}
        </h1>
        <VasionButton
          id="preview"
          :classProp="'primary'"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          SAVE
        </VasionButton>
      </div>

      <!-- TABS -->
      <div class="md-layout">
        <VasionButton
          id="tab-settings"
          :isInErrorState="settingsErrorState"
          :classProp="calculatedClass('settings')"
          @vasionButtonClicked="updateSelectedTab('settings')"
        >
          SETTINGS
        </VasionButton>
        <VasionButton
          id="tab-status"
          :isInErrorState="statusNotificationsErrorState"
          :classProp="calculatedClass('status')"
          @vasionButtonClicked="updateSelectedTab('status')"
        >
          STATUS NOTIFICATIONS
        </VasionButton>
        <VasionButton
          id="tab-permissions"
          :isInErrorState="permissionsErrorState"
          :classProp="calculatedClass('permissions')"
          @vasionButtonClicked="updateSelectedTab('permissions')"
        >
          PERMISSIONS
        </VasionButton>
      </div>

      <!-- SETTINGS -->
      <div v-show="selectedTabName === 'settings'" id="settings-tab" class="tab">
        <WorkflowSettings
          ref="settings"
          @markAsDirty="markAsDirty"
          @addEmailTemplateClick="addEmailTemplateClick"
          @configureStep="startConfigureStep"
          @isLoading="setIsLoading"
          @errorInTab="setTabErrorState('settings')"
        />
      </div>

      <!-- STATUS -->
      <div v-show="selectedTabName === 'status'" id="status-tab" class="tab">
        <WorkflowStatus
          ref="status"
          :workflowId="workflowId"
          @markAsDirty="markAsDirty"
        />
      </div>

      <!-- PERMISSIONS -->
      <div v-show="selectedTabName === 'permissions'" id="permissions-tab" class="tab">
        <div>
          <VasionListSelection
            :available-fields.sync="availableUsers"
            :selected-fields.sync="assignedUsers"
            :allow-field-ordering="false"
            :available-label-text="'Initiating Users'"
            :selected-label-text="'Permitted Users'"
            :unique-id="'permissions-users'"
            @update:availableFields="markAsDirty"
            @update:selectedFields="markAsDirty"
            @update:addAll="markAsDirty"
            @update:removeAll="markAsDirty"
          />
        </div>
        <div class="separator-div" />
        <div>
          <VasionListSelection
            :available-fields.sync="availableGroups"
            :selected-fields.sync="assignedGroups"
            :allow-field-ordering="false"
            :available-label-text="'Initiating Groups'"
            :selected-label-text="'Permitted Groups'"
            :unique-id="'permissions-groups'"
            @update:availableFields="markAsDirty"
            @update:selectedFields="markAsDirty"
            @update:addAll="markAsDirty"
            @update:removeAll="markAsDirty"
          />
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog :message="leaveMessage" @noButtonClick="cancelLeave()" @yesButtonClick="doLeave()" />
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

import EmailTemplate from '@/views/admin/EmailTemplate.vue';
import WorkflowSettings from '@/components/workflow/WorkflowSettings.vue';
import WorkflowStatus from '@/components/workflow/WorkflowStatus.vue';
import WorkflowStepDetails from '@/components/workflow/WorkflowStepDetails.vue'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TheWorkflowAdmin',
  components: {
    EmailTemplate,
    Loading,
    WorkflowSettings,
    WorkflowStatus,
    WorkflowStepDetails,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      allowLeave: false,
      assignedGroups: [],
      assignedUsers: [],
      availableGroups: [],
      availableUsers: [],
      editStepData: {},
      fullPage: true,
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      prevActive: '',
      selectedTabName: 'settings',
      showEmailTemplate: false,
      showLeaveDialog: false,
      showSnackbar: false,
      showStepDetails: false,
      showWorkflow: true,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    active() { return this.$store.state.mainViews.mainNav },
    leaveMessage() { return `Are you sure you want to leave? Changes to "${this.workflow.name}" have not been saved.` },
    newWorkflowClicked() { return this.$store.state.workflow.newWorkflowClicked },
    permissionsErrorState() { return this.$store.state.workflow.designerTabErrorConfig.permissions },
    saveWorkflowID() { return this.$store.state.admin.saveWorkflowID },
    settingsErrorState() { return this.$store.state.workflow.designerTabErrorConfig.settings },
    statusNotificationsErrorState() { return this.$store.state.workflow.designerTabErrorConfig.statusNotification },
    workflow() { return this.$store.state.workflow.selectedWorkflow },
    workflowId() { return this.workflow && this.workflow.id ? this.workflow.id : 0 },
  },
  watch: {
    active: function () {
      if (this.isDirty && this.prevActive !== this.active) {
        this.toggleLeaveDialog()
      }
    },
    newWorkflowClicked: function () {
      if (this.isDirty && this.newWorkflowClicked) {
        this.toggleLeaveDialog()
      }
    },
    workflowId: function () { this.reloadWorkflowData() },
  },
  created: function () {
    this.$material.locale.dateFormat = 'MM/dd/yyyy'
    this.$store.dispatch('vault/getVaultList')
    this.$store.dispatch('admin/setSaveWorkflowID', 0)
    this.prevActive = this.active === 'workflow-designer' ? `configure-workflow-${this.workflow.id}` : this.active
    this.reloadWorkflowData()
  },
  methods: {
    async addEmailTemplateClick() {
      await this.$refs.emailTemplateComponent.clearFields()

      this.showWorkflow = false
      this.showEmailTemplate = true
    },
    calculatedClass(selectedTab) { return this.selectedTabName === selectedTab ? 'text-selected' : 'text' },
    cancelLeave() {
      this.routeTo = null
      this.$store.dispatch('mainViews/changeMainNav', this.prevActive)
      this.$store.dispatch('workflow/setNewWorkflowClicked', false)
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.$refs.settings.clearFieldErrors()
      this.$refs.status.clearSectionErrors()
      this.$store.dispatch('workflow/clearTabErrors')
      this.markAsClean()
      this.toggleLeaveDialog()
      if (this.routeTo && this.routeTo.name !== '') {
        this.$router.push({ name: this.routeTo.name });
        this.routeTo = null
      } else if (this.newWorkflowClicked) {
        await this.$store.dispatch('workflow/selectWorkflow', 0)
        this.$router.push({ name: 'WorkflowAdmin' })
        this.routeTo = null
        this.$store.dispatch('workflow/setNewWorkflowClicked', false)
      } else {
        this.prevActive = this.active
        await this.$store.dispatch('workflow/selectWorkflow', Number(this.active.substring(19)))
      }
    },
    emailTemplateComplete() {
      this.$store.dispatch('common/getEmailTemplates')
      this.showEmailTemplate = false
      this.showWorkflow = true
    },
    hideEditStepData() {
      this.showStepDetails = false
      this.showWorkflow = true
      this.editStepData = {}
    },
    markAsClean() {
      this.isDirty = false
      this.$store.dispatch('workflow/setIsWorkflowDirty', false)
    },
    markAsDirty() {
      this.isDirty = true
      this.$store.dispatch('workflow/setIsWorkflowDirty', true)
      this.$store.dispatch('workflow/clearTabError', 'permissions')
    },
    pushToSecurityArrays(securityData) {
      if (securityData.availableGroups) {
          securityData.availableGroups.forEach((element) => {
            this.availableGroups.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }

        if (securityData.assignedGroups) {
          securityData.assignedGroups.forEach((element) => {
            this.assignedGroups.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }

        if (securityData.availableUsers) {
          securityData.availableUsers.forEach((element) => {
            this.availableUsers.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }

        if (securityData.assignedUsers) {
          securityData.assignedUsers.forEach((element) => {
            this.assignedUsers.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }
    },
    reloadWorkflowData() {
      this.availableGroups = []
      this.assignedGroups = []
      this.availableUsers = []
      this.assignedUsers = []
      // The ".then" instead of "await" is on purpose
      // There will be a handful of server-side calls here to load up different sections of this view
      // want to do those in parallel instead of having to wait for each one
      this.$store.dispatch('workflow/getWorkflowSecurity', this.workflowId).then((securityData) => {
        if (securityData) {
          this.pushToSecurityArrays(securityData)
        }
      })
    },
    async save() {
      this.showSnackbar = false
      this.isLoading = true

      let settingsSaveResults = null
      try {
        settingsSaveResults = await this.$refs.settings.save()
      } catch {
        settingsSaveResults = null
      }

      if (!settingsSaveResults || this.saveWorkflowID <= 0) {
        this.snackbarTitle = 'Unable to save Workflow'
        this.snackbarSubTitle = !settingsSaveResults || settingsSaveResults.message === '' ? 'Error saving Workflow Settings\n' : settingsSaveResults.message
        this.snackbarImage = false
        this.showSnackbar = true;
        this.isLoading = false
        return false
      }

      const promise1 = this.$refs.status.save()
      const promise2 = this.saveSecurity()

      const saveResultsArray = await Promise.all([promise1, promise2])

      let displayMessage = settingsSaveResults.message
      let saveSuccess = settingsSaveResults.success

      saveResultsArray.map((element) => {
        saveSuccess = saveSuccess && element.success
        displayMessage += element.message

        return true
      })

      if (saveSuccess === true) {
        this.snackbarTitle = 'Workflow Saved!'
        this.snackbarSubTitle = 'All settings saved successfully'
        this.snackbarImage = true
        this.markAsClean()
      } else {
        this.snackbarTitle = 'Unable to save Workflow'
        this.snackbarSubTitle = displayMessage
        this.snackbarImage = false
      }
      this.showSnackbar = true;
      this.isLoading = false
      return saveSuccess
    },
    async saveSecurity() {
      if (this.saveWorkflowID <= 0) {
        return false
      }

      const payload = {
        workflowID: this.saveWorkflowID,
        assignedUsers: this.assignedUsers.map((user) => user.value),
        assignedGroups: this.assignedGroups.map((group) => group.value),
      }

      const saveResult = await this.$store.dispatch('workflow/saveWorkflowSecurity', payload)
      if (!saveResult) {
        this.$store.dispatch('workflow/setWorkflowTabError', 'permissions')
      }
      const returnData = {
        success: saveResult,
        message: saveResult ? '' : 'Error saving Security. ',
      }

      return returnData
    },
    setIsLoading(payload) { this.isLoading = payload.isLoading },
    showPreview() {
      // TODO: This is really just a placeholder for now
    },
    startConfigureStep(stepData) {
      this.editStepData = JSON.parse(JSON.stringify(stepData))
      this.showWorkflow = false
      this.showStepDetails = true
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    updateSelectedTab(newSelectedTab) { this.selectedTabName = newSelectedTab },
    updateWorkflowStep(stepData) {
      this.$refs.settings.updateLocalStep(stepData)
      this.markAsDirty()
      this.hideEditStepData()
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';

  .main {
    background-color: white;
    padding: 20px 0 0 15px;
    width: 100%;
    z-index: 1;

    #toolbar {
      height: 40px;
      margin: 0;
      width: 100%;
      text-align: left;
      line-height: 40px;
      vertical-align: middle;
      min-width: 500px;

      #title {
        @include Headline;
        float: left;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        margin: 0;

        &.new-workflow-name {
          color: $grey-400;
        }
      }

      #button-favorite {
        margin: 0 25px 0 25px;
        float: left;
      }

      #preview {
        float: right;
        margin-right: 10px;

        &.vasion-button {
          button {
            margin-top: 0;
          }
        }
      }
    }

    #tab-settings {
      &.vasion-button {
        &:first-child {
          button {
            margin-left: 0;
          }
        }

        &.isactive {
          button {
            background-color: $orange-75 !important;
          }
        }
      }
    }
  }

  .tab {
    width: calc(100vw - 290px);
    height: calc(100vh - 175px);
    overflow-y: auto;
    overflow-x: none;
  }
  .tab-error-state {
    border: 1px solid $error-red;
  }

  .separator-div {
    height: 40px;
  }

</style>
