<template>
  <div v-if="localFormID > 0">
    <div class="md-layout">
      <div class="md-layout-item w100">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedField"
          :isDisabled="localLockFields"
          :dataArray="indexFields"
          width="341"
          title="PARAMETER FIELD"
          type="plain-list"
          displayName="name"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <div class="separator-16" />

        <VasionDropList
          v-slot="slotItem"
          v-model="localValueType"
          :dataArray="valueTypeArray"
          :isDisabled="localLockFields"
          width="341"
          title="VALUE TYPE"
          type="plain-list"
          displayName="name"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
    </div>
    <div class="md-layout param-div">
      <div class="md-layout-item w100">
        <VasionDropList
          v-slot="slotItem"
          v-model="compareType1"
          :dataArray="compareTypeList"
          width="341"
          title="VALUE"
          type="plain-list"
          displayName="name"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <div class="separator-16" />
        <VasionInput
          v-if="!showDateField"
          v-model="compareValue1"
          name="compare-value-one"
          class="input-style"
          inputType="top-white"
          width="341"
          :showTitlePlaceholder="true"
        />
      </div>
      <div v-if="showDateField" class="md-layout-item">
        <md-datepicker id="date-value-one" v-model="compareValue1" name="date-value-one">
          <label />
        </md-datepicker>
      </div>
      <div v-if="showSecondParam" class="separator-16" />
      <div v-if="showSecondParam" class="md-layout-item">
        <VasionDropList
          v-slot="slotItem"
          v-model="compareType2"
          :dataArray="compareTypeList"
          width="100%"
          title="AND"
          type="plain-list"
          displayName="name"
          valueName="name"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div v-if="showSecondParam" class="separator-16" />
      <div v-if="showSecondParam" class="md-layout-item">
        <VasionInput
          v-if="!showDateField"
          id="compare-value-two"
          v-model="compareValue2"
          name="compare-value-two"
          class="input-style"
          inputType="top-white"
          width="100%"
          :showTitlePlaceholder="true"
        />
        <md-datepicker
          v-if="showDateField"
          id="date-value-two"
          v-model="compareValue2"
          name="date-value-two"
        >
          <label />
        </md-datepicker>
      </div>
    </div>
    <label class="error-text">{{ errorText }}</label>
  </div>
</template>

<script>
export default {
  name: 'AutoStepParameter',
  props: {
    propFieldName: {
      type: String,
      default: '',
      required: false,
    },
    propFormID: {
      type: Number,
      default: 0,
      required: true,
    },
    propLockFields: {
      type: Boolean,
      default: false,
      required: false,
    },
    propValueType: {
      type: String,
      default: 'Text',
      required: false,
    },
  },
  data: function () {
    return {
      compareType1: { name: '' },
      compareType2: { name: '' },
      compareValue1: '',
      compareValue2: '',
      errorText: '',
      ignoreFieldUpdate: false,
      localFieldName: '',
      localFormID: 0,
      localLockFields: false,
      localValueType: {
        name: '',
      },
      selectedField: {
        name: '',
        value: '',
      },
      stringCompare: [
        { name: 'Equal' },
        { name: 'Starts With' },
        { name: 'Ends With' },
        { name: 'Contains' },
      ],
      valueCompare: [
        { name: '=' },
        { name: '>' },
        { name: '>=' },
        { name: '<' },
        { name: '<=' },
        { name: '<>' },
      ],
      valueTypeArray: [
        { name: 'Text' },
        { name: 'Number' },
        { name: 'Date' },
      ],
    }
  },
  computed: {
    compareTypeList() { return this.localValueType.name === 'Text' ? this.stringCompare : this.valueCompare },
    indexFields() { return this.$store.state.common.indexFields },
    showDateField() { return this.localValueType.name === 'Date' },
    showSecondParam() { return this.localValueType.name !== 'Text' },
  },
  watch: {
    localValueType: function () {
      this.compareType1 = { name: '' }
      this.compareType2 = { name: '' }

      this.resetCompareFields()
    },
    propFieldName: function () {
      if (!this.propFieldName || this.propFieldName === '') {
        return
      }

      const foundField = this.indexFields.find((field) => {
        return field.value === this.propFieldName
      })

      if (foundField) {
        this.selectedField = foundField
      } else {
        this.selectedField = {
          name: '',
          value: '',
        }
      }
    },
    propFormID: function () {
      this.localFormID = this.propFormID
      this.reloadFields()
    },
    propLockFields: function () {
      this.localLockFields = this.propLockFields
    },
    propValueType: function () {
      if (this.propValueType && this.propValueType !== '') {
        this.localValueType = {
          name: this.propValueType,
        }
      }
    },
  },
  created: function () {
    this.$material.locale.dateFormat = 'MM/dd/yyyy'

    this.localFormID = this.propFormID
    this.localValueType = {
      name: this.propValueType ? this.propValueType : 'Text',
    }
    this.localLockFields = this.propLockFields

    this.selectedField = {
      name: '',
      value: this.propFieldName,
    }

    this.reloadFields()
  },
  methods: {
    getParamData() {
      this.errorText = ''
      let result

      let validData = false
      if (!this.selectedField || this.selectedField.value === '') {
        this.errorText = 'Please select a Parameter'
      } else if (!this.localValueType || this.localValueType.name === '') {
        this.errorText = 'Please select a Value Type'
      } else if (this.compareValue1 === '' && this.compareValue2 === '') {
        this.errorText = 'Please enter a comparison value'
      } else if (this.compareValue1 !== '' && (!this.compareType1 || this.compareType1.name === '')) {
        this.errorText = 'Please enter a Comparison Type for the first Value'
      } else if (this.compareValue2 !== '' && (!this.compareType2 || this.compareType2.name === '')) {
        this.errorText = 'Please enter a Comparison Type for the second Value'
      } else {
        validData = true
      }

      if (validData === false) {
        return result
      }

      const foundField = this.indexFields.find((field) => {
        return field.value === this.selectedField.value
      })

      if (!foundField) {
        this.errorText = 'Invalid Parameter Selected'
        return result
      }

      result = {
        fieldID: foundField.fieldID,
        firstValue: !this.compareValue1 || this.compareValue1 === '' ? this.compareValue2 : this.compareValue1,
        secondValue: !this.compareValue1 || this.compareValue1 === '' ? '' : this.compareValue2, // This is a little confusing, but if the user enters only something for the second value, we want to use that in the first
        valueType: this.localValueType.name,
        firstValueOperator: !this.compareValue1 || this.compareValue1 === '' ? this.compareType2.name : this.compareType1.name,
        secondValueOperator: !this.compareValue1 || this.compareValue1 === '' ? '' : this.compareType2.name,
      }

      return result
    },
    async reloadFields() {
      if (this.localFormID <= 0) {
        return
      }

      await this.$store.dispatch('common/getIndexFieldsForForm', this.localFormID)

      // the previously selected field may be in the new list of fields
      // if it is, use it, otherwise, reset the drop down
      this.updateSelectedTitleFromFieldName()
    },
    resetCompareFields() {
      this.errorText = ''

      this.compareValue1 = ''
      this.compareValue2 = ''
    },
    updateSelectedTitleFromFieldName() {
      const foundField = this.indexFields.find((field) => {
        return field.value === this.selectedField.value
      })

      if (foundField) {
        this.selectedField = foundField
      } else {
        this.selectedField = {
          name: '',
          value: '',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

  .w100 {
    width: 100%;
    display: flex;
  }

  .separator-16 {
    width: 16px;
  }

  .param-div {
    padding-top: 15px;
  }

  .error-text {
    color: red;
    font-weight: bold;
  }

</style>
